import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// 初始化CSS
import './assets/css/base.css'
import './assets/css/common.css'

// 页面标题
import webTitle from './utils/v-web-title'
Vue.use(webTitle);

// 字体图标
import './assets/iconfont/iconfont.css'

// Element
import './utils/element.js'
import 'element-ui/lib/theme-chalk/index.css';

// animate
import animated from 'animate.css'
Vue.use(animated)

// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'N6jIs2cocxjm4sKHf33jTfoMjnc9IVIB'
})

// 图片预览插件vue-photo-preview
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
var option = {
  maxSpreadZoom: 2, // 控制预览图最大的倍数，默认是2倍
  fullscreenEl: false, //控制是否显示右上角全屏按钮
  closeEl: true, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: false, //控制是否显示放大缩小按钮
  counterEl: false, //控制是否显示左上角图片数量按钮
  arrowEl: true,  //控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: true, //点击应切换控件的可见性
  clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
}
Vue.use(preview, option)

// moment.js
import moment from 'moment'
Vue.prototype.$moment = moment

// 通用下载方法
import { download } from '@/utils/request'
Vue.prototype.$download = download

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
