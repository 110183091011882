<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "App",

  data() {
    return {
      _beforeUnload_time: "",
      _gap_time: "",
    };
  },

  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
  },

  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },

  methods: {
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime();
    },

    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        //如果是登录状态，关闭窗口前，移除用户
        Cookies.remove("account");
        Cookies.remove("Admin-Token");
        Cookies.remove("user_Id");
        localStorage.clear();
      }
    },
  },
};
</script>


<style>
</style>
