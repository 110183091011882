import Vue from 'vue'
import VueRouter from 'vue-router'

// 路由懒加载
// 首页
const Index = () => import(/* webpackChunkName: "Index" */ '../views/Index/index.vue')
// 服务中心
const ServiceCenterIndex = () => import(/* webpackChunkName: "ServiceCenter" */ '../views/ServiceCenter/index.vue')
const ServiceCenterIntroduce = () => import(/* webpackChunkName: "ServiceCenter" */ '../views/ServiceCenter/introduce.vue')
// 会员中心
const MemberCenterLoginAndReg = () => import(/* webpackChunkName: "MemberCenter" */ '../views/MemberCenter/LoginAndReg.vue')
const OrderAndInfo = () => import(/* webpackChunkName: "MemberCenter" */ '../views/MemberCenter/OrderAndInfo.vue')
// 资源中心
const ResourceCenterDocumentIndex = () => import(/* webpackChunkName: "ResourceCenter" */ '../views/ResourceCenter/DocumentIndex.vue')
const ResourceCenterDocumentDetails = () => import(/* webpackChunkName: "ResourceCenter" */ '../views/ResourceCenter/DocumentDetails.vue')
const ResourceCenterVideoIndex = () => import(/* webpackChunkName: "ResourceCenter" */ '../views/ResourceCenter/VideoIndex.vue')
const ResourceCenterVideoDetails = () => import(/* webpackChunkName: "ResourceCenter" */ '../views/ResourceCenter/VideoDetails.vue')
const ResourceCenterSearchPage = () => import(/* webpackChunkName: "ResourceCenter" */ '../views/ResourceCenter/SearchPage.vue')
// 关于我们
const ContactUs = () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutUs/ContactUs.vue')
const SituationAndHonor = () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutUs/SituationAndHonor.vue')
// 专业团队
const TeamIndex = () => import(/* webpackChunkName: "Team" */ '../views/Team/index.vue')
// 通用页面
const CommonList = () => import(/* webpackChunkName: "Common" */ '../views/Common/list.vue')
const CommonDetail = () => import(/* webpackChunkName: "Common" */ '../views/Common/detail.vue')

Vue.use(VueRouter)

// 避免重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/ServiceCenter/index',
    name: 'ServiceCenterIndex',
    component: ServiceCenterIndex
  },
  {
    path: '/ServiceCenter/introduce',
    name: 'ServiceCenterIntroduce',
    component: ServiceCenterIntroduce
  },
  {
    path: '/MemberCenter/LoginAndReg',
    name: 'MemberCenterLoginAndReg',
    component: MemberCenterLoginAndReg
  },
  {
    path: '/MemberCenter/OrderAndInfo',
    name: 'OrderAndInfo',
    component: OrderAndInfo
  },
  {
    path: '/ResourceCenter/DocumentIndex',
    name: 'ResourceCenterDocumentIndex',
    component: ResourceCenterDocumentIndex
  },
  {
    path: '/ResourceCenter/DocumentDetails',
    name: 'ResourceCenterDocumentDetails',
    component: ResourceCenterDocumentDetails
  },
  {
    path: '/ResourceCenter/VideoIndex',
    name: 'ResourceCenterVideoIndex',
    component: ResourceCenterVideoIndex
  },
  {
    path: '/ResourceCenter/VideoDetails',
    name: 'ResourceCenterVideoDetails',
    component: ResourceCenterVideoDetails
  },
  {
    path: '/ResourceCenter/SearchPage',
    name: 'ResourceCenterSearchPage',
    component: ResourceCenterSearchPage
  },
  {
    path: '/AboutUs/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/Team/index',
    name: 'TeamIndex',
    component: TeamIndex
  },
  {
    path: '/AboutUs/SituationAndHonor',
    name: 'SituationAndHonor',
    component: SituationAndHonor
  },
  {
    path: '/Common/list',
    name: 'CommonList',
    component: CommonList
  },
  {
    path: '/Common/detail',
    name: 'CommonDetail',
    component: CommonDetail
  },
]

const router = new VueRouter({
  routes
})

// 跳转时定位到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router
