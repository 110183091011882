import Vue from 'vue'
import {
    Tabs, TabPane, Breadcrumb,
    BreadcrumbItem, Input, DatePicker, Table,
    TableColumn, Pagination, Form,
    FormItem, Button, Message, Dialog, MessageBox, Tag, Radio
} from 'element-ui'

Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Input);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Tag);
Vue.use(Radio);

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
